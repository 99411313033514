import React from "react"

import HeaderResources from "../../components/header_resources"

export default function InvestmentLetters() {
  const heroTitle = "Media"

  return (
    <HeaderResources heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <ul class="">
        <li>
          Discussion centered around investing in thematic and sectoral ETFs and mutual funds.
        </li>
        <li>VCL framework: volatility, conviction and liquidity are key considerations.</li>
        <li>Investable ideas for 2022.</li>
      </ul>

      <div class="w-100 mt-4 mt-md-5">
        <div class="embed-responsive embed-responsive-16by9 radius-sm sm:mmb-5">
          <iframe
            title="Media"
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/IB_qKoklFJk?rel=0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </HeaderResources>
  )
}
